const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    siteUrl: 'https://redcarpet.xeoscript.com',
    auth: {
        login: baseUrl + 'auth/login/',
        status: baseUrl + 'auth/status/'
    },
    gallery: {
        // list: baseUrl + 'gallery/list/'
        list: baseUrl + 'gallery/list-gallery/'
    },
    getquote: {
        add: baseUrl + 'getquote/add/'
    }
};
